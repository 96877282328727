<template>
  <MasterTable
    title="Kundtillgänglighetsnivåer"
    :rows="customerAvailabilityStatus"
    :columns="columns"
    :loading="loading"
    :update="update"
    :reset-form-model="resetFormModel"
    :form-model="model"
    :export-data="exportData"
    remove-item-text-prop="name"
    @update-form-model="(row) => (model = row)"
  >
    <template v-slot:form>
      <div class="row" style="min-width: 300px">
        <div class="col-6 q-pa-md">
          <q-input dense v-model="model.level" label="Nivå" readonly />
        </div>
        <div class="col-6 q-pa-md">
          <q-input dense v-model="model.status" label="Status" readonly />
        </div>
        <div class="col-3 q-pa-md">
          <span class="text-caption text-grey-8 q-pb-sm">Statusfärg</span>
          <q-color
            no-header
            square
            no-header-tabs
            no-footer
            v-model="model.color"
            :rules="[requiredRule]"
          />
        </div>
      </div>
    </template>
  </MasterTable>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import MasterTable from '@/components/master/MasterTable.vue'
import { useCustomerAvailabilityStatus } from '@/composable/useCustomerAvailabilityStatus'
import { VehicleTypeGroup } from '@/types/vehicle-type-group'
import {
  required as requiredRule,
  booleanTrueRequired,
  requiredNotNegative,
} from '@/common/formValidationRules'
import { CustomerAvailabilityStatus } from '@/types/customer-availability-status'

export default defineComponent({
  name: 'MasterCustomerAvailabilityStatus',

  components: {
    MasterTable,
  },

  setup() {
    const {
      loading: loadingStateCustomerAvailabilityStatus,
      data: customerAvailabilityStatus,
      update,
    } = useCustomerAvailabilityStatus(true)

    function createFormModel(): Partial<CustomerAvailabilityStatus> {
      return {}
    }
    const model = ref<Partial<VehicleTypeGroup>>(createFormModel())

    function resetFormModel() {
      model.value = createFormModel()
    }

    const columns = [
      {
        name: 'level',
        label: 'Nivå',
        align: 'left',
        field: 'level',
        sortable: true,
      },
      {
        name: 'status',
        label: 'Status',
        align: 'left',
        field: 'status',
        sortable: true,
      },
      {
        name: 'color',
        label: 'Färg',
        align: 'left',
        field: 'color',
        sortable: true,
      },
    ]

    const loading = computed(() => {
      return {
        ...loadingStateCustomerAvailabilityStatus.value,
      }
    })

    const exportData = computed(() => {
      return customerAvailabilityStatus.value.map((customerAvailability) => {
        return {
          Nivå: customerAvailability.level,
          Status: customerAvailability.status,
          Färg: customerAvailability.color,
        }
      })
    })

    return {
      columns,
      model,
      requiredRule,
      requiredNotNegative,
      booleanTrueRequired,
      customerAvailabilityStatus,
      update,
      loading,
      resetFormModel,
      exportData,
    }
  },
})
</script>
