import { render } from "./CustomerAvailabilityStatus.vue?vue&type=template&id=2aafa5f8"
import script from "./CustomerAvailabilityStatus.vue?vue&type=script&lang=ts"
export * from "./CustomerAvailabilityStatus.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QColor from 'quasar/src/components/color/QColor.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QColor});
