
import { defineComponent, computed, ref } from 'vue'
import MasterTable from '@/components/master/MasterTable.vue'
import { useCustomerAvailabilityStatus } from '@/composable/useCustomerAvailabilityStatus'
import { VehicleTypeGroup } from '@/types/vehicle-type-group'
import {
  required as requiredRule,
  booleanTrueRequired,
  requiredNotNegative,
} from '@/common/formValidationRules'
import { CustomerAvailabilityStatus } from '@/types/customer-availability-status'

export default defineComponent({
  name: 'MasterCustomerAvailabilityStatus',

  components: {
    MasterTable,
  },

  setup() {
    const {
      loading: loadingStateCustomerAvailabilityStatus,
      data: customerAvailabilityStatus,
      update,
    } = useCustomerAvailabilityStatus(true)

    function createFormModel(): Partial<CustomerAvailabilityStatus> {
      return {}
    }
    const model = ref<Partial<VehicleTypeGroup>>(createFormModel())

    function resetFormModel() {
      model.value = createFormModel()
    }

    const columns = [
      {
        name: 'level',
        label: 'Nivå',
        align: 'left',
        field: 'level',
        sortable: true,
      },
      {
        name: 'status',
        label: 'Status',
        align: 'left',
        field: 'status',
        sortable: true,
      },
      {
        name: 'color',
        label: 'Färg',
        align: 'left',
        field: 'color',
        sortable: true,
      },
    ]

    const loading = computed(() => {
      return {
        ...loadingStateCustomerAvailabilityStatus.value,
      }
    })

    const exportData = computed(() => {
      return customerAvailabilityStatus.value.map((customerAvailability) => {
        return {
          Nivå: customerAvailability.level,
          Status: customerAvailability.status,
          Färg: customerAvailability.color,
        }
      })
    })

    return {
      columns,
      model,
      requiredRule,
      requiredNotNegative,
      booleanTrueRequired,
      customerAvailabilityStatus,
      update,
      loading,
      resetFormModel,
      exportData,
    }
  },
})
